// =========
/// Colors
// =========

$color-primary: #C31924;
$color-secondary: #c1aa4c;
$color-secondary-transparent: rgba(187, 167, 86, 0.6);
$color-secondary-pale: rgba(193, 170, 76, 0.2);
$color-black: #000;
$color-dark-grey: #425658;
$color-white: #fff;
$color-grey: #ccc;
$color-light-grey: #f7f7f7;
$dark-transparent: rgba(0,0,0,0.75);


// media queries - breakpoints

$breakpoint-desktop-small: 1400px;
$breakpoint-large: 1200px;
$breakpoint-medium-large: 1020px;
$breakpoint-medium: 900px;
$breakpoint-medium-small: 975px;
$breakpoint-small: 800px;
$breakpoint-smaller: 650px;
$breakpoint-smallest: 500px;
$breakpoint-mobile: 410px;