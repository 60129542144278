.test-section {

  .zweispalter {
    .col-25 {
      width: 37%;
    }

    .col-25-2 {
      width: 48%;
    }

  }

  .test-img-div-1 {
    clip-path: polygon(0 0, 100% 0%, calc(100% - 165px) 100%, 0 100%); /* polygon(point1, point2, point3, point4) */
    margin-right: -150px;

    background: url("../img/weber-bauunternehmen/vom-fass/weber-bauunternehmen-vom-fass-01.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }

  .test-img-div-2 {
    clip-path: polygon(165px 0, 100% 0%, calc(100% - 165px) 100%, 0 100%); /* polygon(point1, point2, point3, point4) */
    margin-right: -150px;

    background: url("../img/weber-bauunternehmen/dietz/weber-bauunternehmen-dietz-01.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }

  .test-img-div-3 {
    clip-path: polygon(165px 0, 100% 0%, calc(100% - 165px) 100%, 0 100%); /* polygon(point1, point2, point3, point4) */
    margin-right: -150px;

    background: url("../img/weber-bauunternehmen/wohnhaus-l/weber-bauunternehmen-wohnhaus-l-01.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }

  .test-img-div-4 {
    clip-path: polygon(165px 0, 100% 0%, 100% 100%, 0 100%); /* polygon(point1, point2, point3, point4) */

    background: url("../img/weber-bauunternehmen/evangelische-stadtkirche/weber-bauunternehmen-evangelische-stadtkirche-rv-02.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }


  .header-svg {
    position: absolute;
    z-index: 99;
    width: 20vw;
    height: auto;
  }


  .header-svg-1 {
    top: 30%;
    right: 75%
  }

  .header-svg-2 {
    top: 42%;
    right: 49%;
  }

  .header-svg-3 {
    top: 64%;
    right: 24%
  }

  .header-svg-4 {
    top: 53%;
    right: 0;
  }

  @media (max-width: 1570px) {
    .header-svg-1 {
      right: 74.4%
    }
    .header-svg-3 {
      right: 24.6%
    }
  }

  @media (max-width: 1300px) {
    .header-svg-1 {
      right: 73.8%
    }
    .header-svg-3 {
      right: 25.1%
    }
  }

  @media (max-width: 1140px) {
    .header-svg-1 {
      right: 73.3%
    }

    .header-svg-2 {
      right: 48.5%;
    }

    .header-svg-3 {
      right: 25.5%
    }
  }


}


.test-section-mobil {
  display: none;

  .zweispalter {
    .col-50 {
      flex-direction: row;

      &:first-child {
        padding-bottom: 5px;
      }

      &:last-child {
        padding-top: 5px;
      }
    }

    .zweispalter-second-lvl {
      height: 50%;

    }
  }


  .test-img-div-1 {
    clip-path: polygon(0 0, 100% 0%, calc(100% - 90px) 100%, 0 100%); /* polygon(point1, point2, point3, point4) */
    margin-right: -40px;

    background: url("../img/weber-bauunternehmen/vom-fass/weber-bauunternehmen-vom-fass-01.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }

  .test-img-div-2 {
    clip-path: polygon(90px 0, 100% 0%, 100% 100%, 0 100%); /* polygon(point1, point2, point3, point4) */
    margin-left: -40px;

    background: url("../img/weber-bauunternehmen/dietz/weber-bauunternehmen-dietz-01.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }

  .test-img-div-3 {
    clip-path: polygon(0 0, 100% 0%, calc(100% - 90px) 100%, 0 100%); /* polygon(point1, point2, point3, point4) */
    margin-right: -40px;

    background: url("../img/weber-bauunternehmen/wohnhaus-l/weber-bauunternehmen-wohnhaus-l-01.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }

  .test-img-div-4 {
    clip-path: polygon(90px 0, 100% 0%, 100% 100%, 0 100%); /* polygon(point1, point2, point3, point4) */
    margin-left: -40px;

    background: url("../img/weber-bauunternehmen/evangelische-stadtkirche/weber-bauunternehmen-evangelische-stadtkirche-rv-02.jpg") center center no-repeat #5E5E5E;
    background-size: cover;
  }


  .header-svg {
    position: absolute;
    z-index: 99;
    width: 35vw;
    height: auto;
  }


  .header-svg-1 {
    top: 32%;
    right: 51.5%;
  }

  .header-svg-2 {
    top: 32%;
    right: 0;
  }

  .header-svg-3 {
    top: 83%;
    right: 51.5%;
  }

  .header-svg-4 {
    top: 83.5%;
    right: 0;
  }

  @media (max-width: $breakpoint-smaller) {

    .header-svg {
      width: 40vw;
    }

    .header-svg-1,
    .header-svg-3 {
      right: 52.5%;
    }
  }
  @media (max-width: $breakpoint-mobile) {

    .header-svg-1,
    .header-svg-3 {
      right: 53.5%;
    }
  }
}

@media (max-width: $breakpoint-medium-large) {
  .test-section {
    display: none;
  }

  .test-section-mobil {
    display: block;
  }
}



