footer {
  background-color: $color-black;
  color: $color-white;

  a{
    transition: color .2s ease-in-out;
    &:hover {
      color: $color-primary;
    }
  }

  svg {
    transition: fill .2s ease-in-out;
    .st0 {
      transition: fill .2s ease-in-out;
      fill: $color-white;
    }
    .st1 {
      transition: fill .2s ease-in-out;
      fill: $color-black;
    }
    &:hover {
      .st0 {
        fill: $color-primary;
      }
    }
  }



  .footer-content {
    display: flex;
    justify-content: space-between;

    padding: 50px 0 100px 0;



    /* ---------- FOOTER BOX 1 ---------- */
    .footer-box-1 {
      display: flex;
      width: 75%;

      .footer-logo {
        width: 200px;
        height: auto;
      }


      .footer-address-blocks {
        display: flex;
      }

      .footer-address-block {
        margin-left: 50px;
      }

    }

    /* ---------- FOOTER BOX 2 ---------- */
    .footer-box-2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;;

      .footer__social-media {
        display: flex;
        justify-content: flex-end;;
        margin-bottom: 20px;

        .svg-footer__social-media {
          width: 40px;
          height: 40px;
          margin-left: 15px;
        }
      }

      .footer__list-blank {
        display: flex;
        list-style: none;
        padding: 0;
        justify-content: flex-end;;

        &_trenner {
          margin: 0 8px;
        }
      }
    }

    @media (max-width: $breakpoint-desktop-small) {

      .footer-box-1 {
        flex-direction: column;
        width: 100%;

        .footer-address-blocks {
          margin-top: 30px;
        }


        .footer-address-block:first-child {
          margin-left: 0;
        }
      }

    }

    @media (max-width: $breakpoint-medium-large) {
      flex-direction: column;

      .footer-box-2 {
        margin-top: 40px;
      }
    }

    @media (max-width: $breakpoint-smaller) {
      .footer-box-1 {
        .footer-address-blocks {
          flex-direction: column;
          margin-top: 0;
        }

        .footer-address-block {
          margin: 30px 0 0 0;
        }
      }

    }

    @media (max-width: $breakpoint-mobile) {
      .footer-box-2 {
        margin-top: 80px;
      }
    }
  }
}
