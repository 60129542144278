.main-wrap {
  width: 85%;
  margin: 0 auto;


  @media (max-width: $breakpoint-large) {
    width: 90%;
  }
}

.second-wrap {
  width: 60%;
  margin: 0 auto;
}

.zweispalter {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  height: 100%;

  @media (max-width: $breakpoint-medium-large) {
    flex-direction: column;

  }
}

.zweispalter-big {
  max-height: 125vh;
  min-height: 1000px;
  overflow: hidden;

  @media (max-width: $breakpoint-medium-large) {
    flex-direction: column;
    max-height: 100%;
  }
}
