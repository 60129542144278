.legal-section {
  width: 60%;
  margin: 50px auto;

  h2, h3, h4  {
    margin: 2rem 0 .5rem 0;
  }


  h1 {
    margin: 2rem 0 1.5rem 0;
  }

  @media (max-width: $breakpoint-large) {
    width: 80%;
  }

  @media (max-width: $breakpoint-small) {
    width: 90%;
  }



}

.half-height {
  height: 50vh;
  min-height: 325px;

  .content-img {
    max-height: 3000px;
    overflow: hidden;
  }
}