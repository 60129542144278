/* signika-300 - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/signika-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/signika-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/signika-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/signika-v12-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/signika-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/signika-v12-latin-300.svg#Signika') format('svg'); /* Legacy iOS */
}

/* signika-regular - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/signika-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/signika-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/signika-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/signika-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/signika-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/signika-v12-latin-regular.svg#Signika') format('svg'); /* Legacy iOS */
}

/* signika-600 - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/signika-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/signika-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/signika-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/signika-v12-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/signika-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/signika-v12-latin-600.svg#Signika') format('svg'); /* Legacy iOS */
}

*::selection {
  background: $color-primary;
}




body {
  font-weight: 200;
  font-size: 20px;
  line-height: 1.4;
  font-family: Signika, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}


footer,
nav,
.full-height,
.legal-section{
  a {
    transition: color .2s ease-in-out;

    &:hover {
      color: $color-primary;
    }
  }
}

.content-black,
.content-red,
.content-white{
  a {
    transition: border-bottom 300ms;
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: inherit;
    }
  }
}

.content-white{
  a {
    color: $color-primary;
  }
}


  ul, li {
  color: inherit;
}

.content-element,
.content-element-bottom {
  ul {
    list-style:none;
  }
  li {
    &:before {
      content: '';
      display: inline-block;
      height: 14px;
      width: 14px;
      background-image: url(../img/icons/list-bullet-marker.svg);
      background-size: contain;
      background-repeat: no-repeat;
      margin-right:0.5em;
    }
  }
}


h1,
h2.h2-home{
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 0.95;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.white-background {
  background-color: $color-white;
  padding: 25px;
  h2.h2-home {
    margin: 0;
    font-weight: 200;
  }
}


h2 {
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
}

h3, h4 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 3%;
}

.margin-top {
  margin-top: 40px;
}

h5 {
  margin-top: 4%;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.25;
}

h6 {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 5%;
}

.h3-lowercase {
  font-size: 1.9rem;
  text-transform: none;
  margin-bottom: 3%;
}

.font-light {
  font-weight: 200;
}

.italic {
  font-style: italic;
}

.h1-subpage {
  font-size: 3rem;
}

@media (max-width: $breakpoint-desktop-small) {
  h4 {
    font-size: 1.4rem;
  }
}

@media (max-width: $breakpoint-large) {
  h3 {
    font-size: 1.4rem;
  }
}

@media (max-width: $breakpoint-medium-large) {
  h3, h4 {
    margin-bottom: 2%;
  }
}

@media (max-width: $breakpoint-small) {
  .h3-lowercase {
    font-size: 1.5rem;
  }
}


@media (max-width: $breakpoint-smaller) {
  body {
    font-size: 18px;
  }

  h1,
  .h1-subpage,
  h2.h2-home{
    font-size: 2.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: $breakpoint-mobile) {
  h1,
  .h1-subpage,
  h2.h2-home {
    font-size: 2.2rem;
  }
  .h3-lowercase {
    font-size: 1.3rem;
  }
}