header {
  .header-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    width: 100%;

    .main-wrap {
      display: flex;
      justify-content: flex-end;
    }
  }


  .header-logo {
    width: 300px;
    height: auto;
    z-index: 999;

    transform-origin: top right;
    transition: transform 188ms ease-in-out;

    .st0{fill:#FFFFFF;}
    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
    .st2{fill:#C31924;}

    @media (max-width: $breakpoint-desktop-small) {
      width: 250px;
    }

    @media (max-width: $breakpoint-large) {
      width: 200px;
    }

    @media (max-width: $breakpoint-medium) {
      width: 160px;
    }
  }

  .logo-transform {
    transform: scale(0.5);

    @media (max-width: $breakpoint-desktop-small) {
      transform: scale(0.6);
    }

    @media (max-width: $breakpoint-large) {
      transform: scale(0.7);
    }

    @media (max-width: $breakpoint-medium) {
      transform: scale(0.8);
    }
  }
}


