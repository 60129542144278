nav {
  .close {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.7s ease-out;
  }

  .menu {
    position: absolute;
    width: 60px;
    height: 60px;
    margin: 60px 0 20px 60px;
    transition: opacity 0.7s ease-out;
    padding: 1px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: $breakpoint-large) {
      width: 50px;
      height: 50px;
      margin: 50px 0 20px 50px;
    }

    @media (max-width: $breakpoint-medium) {
      width: 40px;
      height: 40px;
      margin: 40px 0 20px 40px;
    }

    @media (max-width: $breakpoint-smaller) {
      margin: 30px 0 20px 30px;
    }
  }

  .phone-icon {
    position: absolute;
    width: 40px;
    height: 55px;
    margin: 60px 0 20px 160px;
    transition: opacity 0.7s ease-out;
    padding: 1px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: $breakpoint-large) {
      width: 35px;
      height: 45px;
      margin: 50px 0 20px 130px;
    }

    @media (max-width: $breakpoint-medium) {
      width: 27px;
      height: 37px;
      margin: 40px 0 20px 100px;
    }

    @media (max-width: $breakpoint-smaller) {
      margin: 30px 0 20px 90px;
    }
  }
}

.nav-links {
  text-transform: uppercase;
  position: absolute;
  top: 140px;
  left: -181px;
  width: 250px;
  height: 100%;
  z-index: 2;
  -webkit-transition: left .6s ease-out;
  -moz-transition: left .6s ease-out;
  -o-transition: left .6s ease-out;
  -ms-transition: left .6s ease-out;
  transition: left .6s ease-in-out;

  @media (max-width: $breakpoint-large) {
    top: 120px;
    left: -171px;
  }

  @media (max-width: $breakpoint-medium) {
    top: 100px;
    left: -161px;
  }

  @media (max-width: $breakpoint-smaller) {
    top: 80px;
    left: -151px;
  }

  .nav-link {
    margin: 10px 0;
  }
}

.nav-active {
  .nav-links {
    left: 0;
  }

  .close {
    visibility: visible;
    opacity: 1;
  }

  .open {
    visibility: hidden;
    opacity: 0;
  }

}

.nav-a {
  display: block;
  width: 180px;
  color: $color-white;
  background-color: $color-black;
  padding: 5px 15px 5px 60px;
  transition: background-color .2s ease-in-out;


  &:hover {
    background-color: $color-primary;
    color: $color-white;
  }

  @media (max-width: $breakpoint-large) {
    width: 160px;
    padding-left: 50px;
  }

  @media (max-width: $breakpoint-medium) {
    width: 150px;
    padding-left: 40px;
  }

  @media (max-width: $breakpoint-smaller) {
    width: 128px;
    padding-left: 30px;
  }
}


// Arrows

.arrow-navigation {

  &:hover {

    .arrow {
      color: $color-primary;
    }

  }


  .arrow {
    position: absolute;
    z-index: 50;
    width: 60px;
    height: 60px;
    padding: 10px;
    //background-color: $color-white;

    transition: background-color .2s ease-in-out;


    @media (max-width: $breakpoint-large) {
      width: 50px;
      height: 50px;
      padding: 8px;
    }

    @media (max-width: $breakpoint-small) {
      width: 40px;
      height: 40px;
      padding: 6px;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 35px;
      height: 35px;
      padding: 5px;
    }
  }

  .arrow-description {
    position: absolute;
    z-index: 50;
    top: 48%;
    right: 0;
    transform: translateY(-48%);
    color: $color-black;
    padding: 8px;
    margin-top: -60px;
    background-color: $color-white;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out;

    @media (max-width: $breakpoint-large) {
      margin-top: -54px;
    }

    @media (max-width: $breakpoint-small) {
      margin-top: -48px;
    }
  }

  .up {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .down {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .right {
    top: 48%;
    right: 0;
    transform: translateY(-48%);
  }

  .left {
    top: 48%;
    left: 0;
    transform: translateY(-48%);
  }

  .fixed {
    position: fixed;
  }

}




