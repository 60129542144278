.crop-center,
.crop-left,
.crop-right {
  height: 100%;
}



.crop-center img{
  object-position: center;
}

.crop-center-left img{
  object-position: center left;
}

.crop-center-right img{
  object-position: center right;
}

.crop-top img{
  object-position: top;
}

.crop-top-left img{
  object-position: top left;
}

.crop-top-right img{
  object-position: top right;
}

.crop-bottom img{
  object-position: bottom;
}

.crop-bottom-left img{
  object-position: bottom left;
}

.crop-bottom-right img{
  object-position: bottom right;
}

// SVG Icons + Logo

svg {
  transition: fill .2s ease-in-out;
  .st0,
  .phone-icon {
    transition: fill .2s ease-in-out;
    fill: $color-black;
  }
  .st2 {
    transition: fill .2s ease-in-out;
  }
  .st3,
  .st5{
    transition: fill .2s ease-in-out;
    fill: $color-white;
  }
  .st6{
    transition: fill .2s ease-in-out;
    fill: $color-primary;
  }
  &:hover {
    .st0,
    .st5,
    .phone-icon {
      fill: $color-primary;
    }
    .st2 {
      fill: $color-black;
    }
    .st6 {
      fill: $color-black;
    }
  }
}