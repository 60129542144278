.col-25,
.col-50,
.col-75,
{
  display: flex;
  flex-direction: column;
}

.col-50 {
  width: 50%;
}

.col-75 {
  width: 75%;
}

.col-25 {
  width: 25%;
}

@media (max-width: $breakpoint-medium-large) {
  .col-50 {
    width: 100%;
  }

  .col-75 {
    width: 100%;
  }

  .col-25 {
    width: 100%;
  }
}


.height-33 {
  height: 33.3333% !important;
}

.height-66 {
  height: 66.6666% !important;
}


.content-white,
.content-black,
.content-red {
  padding: 7%;
  flex-grow: 1;
  height: 100%;

  @media (max-width: $breakpoint-medium-large) {
    padding: 7% 50px;
  }

  @media (max-width: $breakpoint-medium) {
    padding: 7% 40px;
  }

  @media (max-width: $breakpoint-smaller) {
    padding: 7% 30px;
  }
}

.height-50-50 {
  .content-element,
  .content-element-bottom {
    height: 100%;
  }
}

.content-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-element-bottom {
  display: flex;
  flex-direction: column;
  justify-content: end;}

.height-13-23 {
  &:first-child {
    height: 33.333%
  }

  &:last-child {
    height: 66.666%
  }
}

.height-23-13 {
  &:first-child {
    height: 66.666%
  }

  &:last-child {

    height: 33.333%
  }
}


.content-white {

}

.content-black {
  background-color: $color-black;
  color: $color-white;
}

.content-red {
  background-color: $color-primary;
  color: $color-white;
}

.content-img {
  height: 100%;

  @media (max-width: $breakpoint-medium-large) {
    max-height: 950px;
    overflow: hidden;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
  }
}