* {
  box-sizing: border-box;
}

html,body {
  height:100%;
}

.white {
  color: $color-white
}

.text-shadow {
  text-shadow: 0 2px 4px #000000;
}