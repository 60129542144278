.full-height {
  height: 100vh;
  min-height: 700px;

  .content-img {
    max-height: 3000px;
    overflow: hidden;
  }
}

.home-section {
  position: relative;

  .content-container {
    position: absolute;
    z-index: 50;
  }

  .center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .top-left {
    top: 220px;
    left: 290px;
    max-width: 72%;

    @media (max-width: $breakpoint-desktop-small) {
      left: 200px;
    }

    @media (max-width: $breakpoint-large) {
      left: 130px;
    }

    @media (max-width: $breakpoint-small) {
      left: 100px;
    }

    @media (max-width: $breakpoint-smaller) {
      top: 190px;
      left: 75px;
    }

    @media (max-width: $breakpoint-smallest) {
      top: 170px;
      left: 65px;
    }

    @media (max-width: $breakpoint-mobile) {
      top: 150px;
      left: 50px;
    }
  }

  .quote {
    top: 20%;
    left: 250px;
    max-width: 25%;

    @media (max-width: $breakpoint-desktop-small) {
      left: 200px;
    }

    @media (max-width: $breakpoint-large) {
      left: 50px;
      max-width: 30%;
    }

    @media (max-width: $breakpoint-medium-large) {
      left: 50px;
      max-width: 35%;
    }

    @media (max-width: $breakpoint-medium) {
      left: 40px;
      max-width: 40%;
    }

    @media (max-width: $breakpoint-medium) {
      bottom: 50px;
      top: auto;
      max-width: 72%;
    }

    @media (max-width: $breakpoint-smaller) {
      left: 30px;
    }

    @media (max-width: $breakpoint-mobile) {
      max-width: 80%;
    }

  }
}